import * as React from "react"

import MenuPage from "../components/menu-page"
import Subscribe from "../components/subscribe"
import Footer from "../components/footer"
import SEO from "../components/seo"

const VpsPackagesPage = () => (
  <div>
      <SEO 
        title="VPS Packages Administration Services" 
        description="Never be worried again about server administration tasks. Have our server admins take care of that for you. This service is priced at just /month."
    />
    <div className="sub-section-wrap">
          <MenuPage/>
    </div>

    <div className="sub-section-wrap py-32">
      <div className="w-full mx-auto bg-white px-5 text-gray-600 mb-2">
          <div className="text-center mx-auto">
              <p className="section-title"><span className="block text-indigo-600 xl:inline">VPS Packages</span> Administration Services<span role="img" aria-label="emoji">✨ </span></p>
              <p className="section-after-title">
              Never be worried again about server administration tasks. Have our server admins take care of that for you. This service is priced at just /month.
              </p>
          </div>
      </div>
    </div>

    <div className="py-12 bg-gray-50">
        <div className="w-full mx-auto px-5 text-gray-600 mb-10">
          <div className="text-center mx-auto">
              <p className="section-after-title text-indigo-800">
              Administration Services
              </p>
          </div>
      </div>
      <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
        <div className="flex flex-wrap -mx-2 overflow-hidden">

            <div className="my-2 px-2 sm:w-2/6 w-full overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Website Hosting Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/monitoring-and-rebooting.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Monitoring & Rebooting</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Server monitoring is a very challenging responsibility for newbie VPS users – you need to 
                        keep an eye on how your virtual server is doing, to determine if it’s overloaded, to see 
                        if all the services are running correctly. It is a procedure that requires undivided 
                        attention, and you most likely don’t the needed time to take care of this. That is 
                        why we offer that we do the task instead of you. If you take advantage of our 
                        Administration Services option, we will be keeping a constant eye on your 
                        VPS server and all the processes running on it. That way you will have 
                        much more time for your web sites or web apps.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="VPS Assistance" 
                            src="https://sheephostingbay.duoservers.com/template2/img/backup-space.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Weekly Backups</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        Keeping a backup of all your data is imperative – one never knows when something can go wrong. 
                        And with our Administration Services solution, you can enjoy regular backups of your entire 
                        VPS, not only of your content, without you having to install and configure anything. What’s 
                        more the backups will be saved on a server with a group of RAID–configured hard disks. It 
                        means that your hosted content will always be protected, regardless of what happens.
                        </p>
                    </div>
                </div>
            </div>

            <div className="my-2 px-2 sm:w-2/6 w-full  overflow-hidden">
                <div className="max-w-md py-4 px-8 mt-20 mb-5">
                    <div className="flex justify-center md:justify-end">
                        <img 
                            className="" 
                            alt="Dedicated Hosting Help" 
                            src="https://sheephostingbay.duoservers.com/template2/img/installation-and-troubleshooting.webp" />
                    </div>
                    <div>
                        <h2 className="text-indigo-800 text-base mb-0 font-semibold">Installation & Troubleshooting</h2>
                        <p className="text-gray-600 text-sm subpixel-antialiased">
                        When using a Linux VPS Package, you have absolute freedom to run any kind of script or service on 
                        your it. Still, a part of the scripts are harder to run and might require some additional setup 
                        on your part. This is when our Administration Services deal can come in real handy. It includes 
                        30 minutes of installation & problem–solving operations – this means that you will be able to 
                        basically employ one of our well–trained server administrators for half an hour and have him 
                        assist you with the installation of any software application that you desire. Alternatively 
                        have him take care of a problem that you are having with your Virtual Private Server.
                        <br/><br/>
                        The Administration Services deal is bundled with most of our OpenVZ Linux VPS Packages and it is an 
                        extra feature with the KVM Linux VPS Packages.
                        </p>
                    </div>
                </div>
            </div>

        </div>
      </div>
    </div>
    
    <Subscribe/>
    <Footer/>
  </div>
)

export default VpsPackagesPage
